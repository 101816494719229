(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/outcome/assets/javascripts/distributed-outcome.js') >= 0) return;  svs.modules.push('/components/lb-ui/outcome/assets/javascripts/distributed-outcome.js');
"use strict";


const {
  clx
} = svs.components.lbUtils;
const {
  useRef,
  useEffect
} = React;
const DistributedOutcome = _ref => {
  let {
    sign,
    dataTestId,
    branding,
    className,
    distribution,
    isCorrect = false,
    isSelected,
    isDisabled
  } = _ref;
  const outcomeClassNames = clx('pg_outcome pg_outcome__distributed', className, {
    'pg_outcome--disabled': isDisabled,
    'pg_outcome--selected': isSelected,
    'pg_outcome--correct': isCorrect,
    'pg_outcome--correct-selected': isCorrect && isSelected,
    'pg_outcome--correct-not-selected': isCorrect && !isSelected,
    'pg_outcome__distributed--not-full': distribution < 100,
    ["pg_outcome--".concat(branding)]: branding
  });
  const outcomeRef = useRef();
  useEffect(() => {
    if (distribution) {
      outcomeRef.current.style.setProperty('--distribution', "".concat(distribution, "%"));
    }
  }, [distribution]);
  return React.createElement("span", {
    className: outcomeClassNames,
    "data-testid": dataTestId,
    ref: outcomeRef
  }, React.createElement("span", {
    className: "pg_outcome__sign"
  }, sign));
};
setGlobal('svs.components.lbUi.outcome.DistributedOutcome', DistributedOutcome);

 })(window);